import React from 'react';
import { RotatingLines } from 'react-loader-spinner'; // Classic spinner
import styled from 'styled-components';

const StyledApp = styled.main`
  background-image: url('/assets/memes/incoming.png'); /* Path to your image */
  background-size: cover; /* Makes the image cover the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex; /* To use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative; /* To position the spinner on top */
`;

const SpinnerContainer = styled.div`
  position: absolute; /* Position spinner relative to the parent container */
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* Ensure the spinner stays on top of everything */
`;

const StyledInfo = styled.div`
  text-align: center;
  color: white;

  position: absolute; /* Position spinner relative to the parent container */
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  h2 {
    font-size: 62px;
    font-family: UbuntuBold;
    color: #f7931a;
    padding:20px;

    span {
      color: #4d4d4d;
    }
  }

  h3 {
    font-size: 40px;
    color: #f7931a;
  }

  h4 {
    color: #f7931a;
    opacity: 0.8;
    padding:2px;
  }

  .nto {
    span {
      font-family: UbuntuBold;
      opacity: 1;
    }
  }
`;

function App() {
  return (
    <StyledApp>
      <SpinnerContainer>
        <RotatingLines
          strokeColor="white"
          strokeWidth="3"
          animationDuration="1"
          width="120"
          visible={true}
        />
      </SpinnerContainer>

      <StyledInfo>
        <h2>
          $<span>n</span>BTC
        </h2>
        <h3>Website and Rebounce incoming</h3>
        <h4 className="nto">
          first <span>N</span>igga <span>T</span>ake <span>O</span>ver in
          history
        </h4>
        <h4>just buy a fucking dolla</h4>
      </StyledInfo>

      {/* Your other content goes here */}
    </StyledApp>
  );
}

export default App;
